import axios from 'axios'
import jwt_decode from 'jwt-decode'
import { inject } from 'mobx-react'
import { Component, createRef } from 'react'
import { Link } from 'react-router-dom'
import { Input } from 'reactstrap'
import FormValidator from '../Forms/FormValidator.js'
import QRCode from 'qrcode'
import '../Pages/login.css'
import '../Pages/twoFactorAuth.css'

@inject('userStore')
class Login extends Component {
  constructor(props) {
    super(props)
    this.state = {
      formLogin: {
        email: '',
        password: ''
      },
      qrCode: null,
      twoFaToken: ['', '', '', '', '', ''],
      setup2Fa: false,
      onlyVerify2Fa: false,
      isLoading: false,
      errorMessage: '',
      secret: ''
    }

    this.twoFaInputs = Array.from({ length: 6 }, () => createRef())
  }

  /**
   * Validate input using onChange event
   * @param  {String} formName The name of the form in the state object
   * @return {Function} a function used for the event
   */
  validateOnChange = (event) => {
    const input = event.target
    const form = input.form
    const value = input.type === 'checkbox' ? input.checked : input.value
    const result = FormValidator.validate(input)

    this.setState({
      [form.name]: {
        ...this.state[form.name],
        [input.name]: value,
        errors: {
          ...this.state[form.name].errors,
          [input.name]: result
        }
      }
    })
  }

  onSubmit = (e) => {
    e.preventDefault()

    const form = e.target
    const inputs = [...form.elements].filter((i) => ['INPUT', 'SELECT'].includes(i.nodeName))

    const { errors, hasError } = FormValidator.bulkValidate(inputs)
    this.setState({
      isLoading: true,
      [form.name]: {
        ...this.state[form.name],
        errors
      },
      errorMessage: ''
    })
    if (!hasError) {
      const token = this.state.twoFaToken.join('')
      const postData = {
        email: this.state.formLogin.email,
        password: this.state.formLogin.password,
        twoFACode: token,
        secret: this.state.secret
      }

      axios
        .post('/auth/login', postData)
        .then((response) => {
          if (response.data.success) {
            localStorage.setItem('token', response.data.token)
            localStorage.setItem('name', response.data.name)
            localStorage.setItem('surname', response.data.surname)
            localStorage.setItem('email', response.data.email)
            localStorage.setItem('function', response.data.function && response.data.function.name)

            axios.defaults.headers.common = {
              Authorization: `Bearer ${response.data.token}`
            }

            const decodedToken = jwt_decode(response.data.token)
            this.props.userStore.setAuthenticated(true)
            this.props.userStore.setUserLevel(decodedToken.user_level)
            this.props.userStore.setUserID(decodedToken.user_id)
            this.props.userStore.setUserEmail(decodedToken.email)
            this.props.userStore.setStartDate(decodedToken.start_date)
            this.props.userStore.setUserHasBirthday(decodedToken.userHasBirthday)

            this.props.history.push('/dashboard')
          } else if (response.data.shouldSetupTwoFA || response.data.shouldProvideTwoFACode) {
            if (response.data.uri) {
              QRCode.toDataURL(response.data.uri, (err, url) => {
                if (err) {
                  console.error(err)
                  this.setState({ errorMessage: 'Failed to generate QR code', isLoading: false })
                } else {
                  this.setState({ qrCode: url })
                }
              })
            }
            this.setState({
              secret: response.data.secret,
              setup2Fa: response.data.shouldSetupTwoFA || false,
              onlyVerify2Fa: response.data.shouldProvideTwoFACode || false,
              isLoading: false
            })
          } else {
            this.setState({
              errorMessage: response.data.message,
              isLoading: false
            })
          }
        })
        .catch((e) => {
          const responseMessage = e.response.data?.message
          if (responseMessage === 'Invalid 2FA token') {
            this.setState({ errorMessage: 'Kodi i pavlefshëm', isLoading: false })
          } else {
            this.setState({ errorMessage: 'Server error', isLoading: false })
          }
        })
    }
  }

  /* Simplify error check */
  hasError = (formName, inputName, method) => {
    return (
      this.state[formName] &&
      this.state[formName].errors &&
      this.state[formName].errors[inputName] &&
      this.state[formName].errors[inputName][method]
    )
  }

  handleTwoFaChange = (e, index) => {
    const { value } = e.target
    if (/^\d$/.test(value)) {
      const newTwoFaToken = [...this.state.twoFaToken]
      newTwoFaToken[index] = value
      this.setState({ twoFaToken: newTwoFaToken }, () => {
        if (index < 5) {
          this.twoFaInputs[index + 1].current.focus()
        }
      })
    } else if (value === '') {
      const newTwoFaToken = [...this.state.twoFaToken]
      newTwoFaToken[index] = ''
      this.setState({ twoFaToken: newTwoFaToken })
    }
  }

  handleTwoFaKeyDown = (e, index) => {
    if (e.key === 'Backspace') {
      if (this.state.twoFaToken[index] === '') {
        if (index > 0) {
          const newTwoFaToken = [...this.state.twoFaToken]
          newTwoFaToken[index - 1] = ''
          this.setState({ twoFaToken: newTwoFaToken }, () => {
            this.twoFaInputs[index - 1].current.focus()
          })
        }
      } else {
        const newTwoFaToken = [...this.state.twoFaToken]
        newTwoFaToken[index] = ''
        this.setState({ twoFaToken: newTwoFaToken })
      }
    } else if (e.key === 'ArrowLeft') {
      if (index > 0) {
        this.twoFaInputs[index - 1].current.focus()
      }
    } else if (e.key === 'ArrowRight') {
      if (index < 5) {
        this.twoFaInputs[index + 1].current.focus()
      }
    }
  }

  handleTwoFaPaste = (e) => {
    e.preventDefault()
    const pasteData = e.clipboardData.getData('text').trim()
    if (/^\d{6}$/.test(pasteData)) {
      const pastedDigits = pasteData.split('')
      this.setState({ twoFaToken: pastedDigits }, () => {
        this.twoFaInputs[5].current.focus()
      })
    }
  }

  render() {
    const disabledBtn = this.state.twoFaToken.filter((item) => item === '').length > 0

    return (
      <div className='row h-100 main-container'>
        <div className='text-center col-lg-7 left-container' style={{ paddingLeft: 0 }}>
          <Link to='/login' className='text-muted'>
            <img className='block-center rounded img-fluid' src='img/logo.png' alt='Logo' />
          </Link>
        </div>
        <span className='divider' />
        <div className='col-lg-3 right-container'>
          <p className='text-center pb-3 pt-2 p'>KYÇU PËR TË VAZHDUAR.</p>

          {this.state.errorMessage !== '' && <p className='text-center text-danger'>{this.state.errorMessage}</p>}
          <form className='mb-3' name='formLogin' onSubmit={this.onSubmit}>
            {!(this.state.onlyVerify2Fa || this.state.setup2Fa) && (
              <div>
                <div className='form-group'>
                  <div className='input-group with-focus'>
                    <Input
                      type='email'
                      name='email'
                      className='border-right-0'
                      placeholder='Përdoruesi'
                      invalid={this.hasError('formLogin', 'email', 'required')}
                      onChange={this.validateOnChange}
                      data-validate='["required"]'
                      value={this.state.formLogin.email}
                    />
                    <div className='input-group-append'>
                      <span className='input-group-text text-muted bg-transparent border-left-0'>
                        <em className='fa fa-envelope' />
                      </span>
                    </div>
                    {this.hasError('formLogin', 'email', 'required') && <span className='invalid-feedback'>Fusha është e nevojshme</span>}
                  </div>
                </div>
                <div className='form-group'>
                  <div className='input-group with-focus'>
                    <Input
                      type='password'
                      id='id-password'
                      name='password'
                      className='border-right-0'
                      placeholder='Fjalëkalimi'
                      invalid={this.hasError('formLogin', 'password', 'required')}
                      onChange={this.validateOnChange}
                      data-validate='["required"]'
                      value={this.state.formLogin.password}
                      autoComplete='off'
                    />
                    <div className='input-group-append'>
                      <span className='input-group-text text-muted bg-transparent border-left-0'>
                        <em className='fa fa-lock' />
                      </span>
                    </div>
                    {this.hasError('formLogin', 'password', 'required') && (
                      <span className='invalid-feedback'>Fusha është e nevojshme</span>
                    )}
                  </div>
                </div>
                <div className='clearfix'>
                  <div className='float-left'>
                    <Link to='/recoverPassword' className='text-muted'>
                      Keni harruar passwordin?
                    </Link>
                  </div>
                </div>
                <button className='btn btn-block btn-info mt-3 login-btn' type='submit'>
                  Kyçu
                </button>
              </div>
            )}

            {this.state.setup2Fa && (
              <div>
                <div className='d-flex justify-content-center align-item-center flex-column'>
                  <h5 className='mb-3 text-center'>Aktivizoni Autentifikimin me Dy Faktorë (2FA)</h5>
                  <p className='text-center'>
                    Për të rritur sigurinë e llogarisë tuaj, ju lutemi scanoni këtë kod QR me një aplikacion si
                    <strong> Google Authenticator.</strong> Pas skanimit, futni kodin e gjeneruar nga aplikacioni në fushën e mëposhtme.
                  </p>
                </div>
                {this.state.qrCode && (
                  <div className='d-flex justify-content-center align-item-center '>
                    <img src={this.state.qrCode} alt='Scan this QR code with your authenticator app' className='mt-3 mb-4' />
                  </div>
                )}
                <div className='two-fa-container'>
                  {this.state.twoFaToken.map((digit, index) => (
                    <input
                      key={index}
                      type='text'
                      maxLength='1'
                      className='two-fa-input'
                      name='twoFaToken'
                      value={digit}
                      onChange={(e) => this.handleTwoFaChange(e, index)}
                      onKeyDown={(e) => this.handleTwoFaKeyDown(e, index)}
                      onPaste={index === 0 ? this.handleTwoFaPaste : undefined}
                      ref={this.twoFaInputs[index]}
                      autoFocus={index === 0}
                      aria-label={`Digit ${index + 1}`}
                    />
                  ))}
                </div>
                <button disabled={disabledBtn} className='btn btn-block btn-info mt-3 login-btn' type='submit'>
                  Verifiko
                </button>
              </div>
            )}

            {this.state.onlyVerify2Fa && (
              <div>
                <div className='d-flex justify-content-center align-item-center flex-column'>
                  <h5 className='mb-3 text-center'>Verifikoni Autentifikimin me Dy Faktorë (2FA)</h5>
                  <p className='text-center'>
                    Për të vazhduar me hyrjen në llogarinë tuaj, ju lutemi futni kodin e gjeneruar nga aplikacioni juaj të autentifikimit.
                    Ky kod përmban <strong>6 shifra</strong> dhe është i vlefshëm për një kohë të shkurtër.
                  </p>
                </div>
                <div className='two-fa-container'>
                  {this.state.twoFaToken.map((digit, index) => (
                    <input
                      key={index}
                      type='text'
                      maxLength='1'
                      className='two-fa-input'
                      value={digit}
                      name='twoFaToken'
                      onChange={(e) => this.handleTwoFaChange(e, index)}
                      onKeyDown={(e) => this.handleTwoFaKeyDown(e, index)}
                      onPaste={index === 0 ? this.handleTwoFaPaste : undefined}
                      ref={this.twoFaInputs[index]}
                      autoFocus={index === 0}
                      aria-label={`Digit ${index + 1}`}
                    />
                  ))}
                </div>
                <button disabled={disabledBtn} className='btn btn-block btn-info mt-3 login-btn' type='submit'>
                  Verifiko
                </button>
              </div>
            )}
          </form>
        </div>
      </div>
    )
  }
}

export default Login
