export const getApplicantsInput = (
  cities,
  activities,
  banks,
  works,
  projects,
  departments,
  managers,
  projectManagers,
  teamLeaders,
  functions,
  languages,
  applicantStatus
) => {
  return [
    {
      text: 'Emri',
      value: 'firstName'
    },
    {
      text: 'Mbiemri',
      value: 'lastName'
    },
    applicantStatus === 'waiting_list'
      ? {
          text: 'Fjalekalimi',
          value: 'password',
          type: 'password'
        }
      : null,
    {
      text: 'Alias',
      value: 'alias'
    },
    {
      text: 'Team',
      value: 'team'
    },
    {
      text: 'Email Adresa',
      value: 'email'
    },
    {
      text: 'Email Personal',
      value: 'personal_email'
    },
    {
      text: 'Numri i leternjoftimit',
      value: 'idNumber'
    },
    {
      text: 'Statusi martesor',
      value: 'marital_status',
      select: [
        { key: '1', value: 'Beqar/e', text: 'Beqar/e' },
        {
          key: '2',
          value: 'I/e martuar',
          text: 'I/e martuar'
        },
        {
          key: '3',
          value: 'I/e divorcuar',
          text: 'I/e divorcuar'
        },
        { key: '4', value: 'I/e ve', text: 'I/e ve' }
      ]
    },
    {
      text: 'Telefoni',
      value: 'contactNumber',
      placeholder: '3834xxxxxxx'
    },
    {
      text: 'Kontakti emergjent',
      value: 'emergencyContactNumber'
    },
    {
      text: 'Adresa',
      value: 'birthplace'
    },
    {
      text: 'Qyteti',
      value: 'city',
      select: cities
    },
    {
      text: 'Aktiviteti',
      value: 'activity',
      select: activities
    },
    {
      text: 'Banka',
      value: 'bank',
      select: banks
    },
    {
      text: 'Llogaria Bankare',
      value: 'bank_account_no'
    },
    {
      text: 'Rroga baze',
      value: 'base_salary',
      type: 'number'
    },
    {
      text: 'Skill Bonus',
      value: 'skill_bonus',
      type: 'number'
    },
    {
      text: 'Koha e punes se punetorit',
      value: 'work_percentage',
      select: [
        { key: '1', value: 100, text: '7,5h' },
        {
          key: '2',
          value: 93.3,
          text: '7,0h'
        },
        {
          key: '3',
          value: 88.8,
          text: '6,66h'
        },
        {
          key: '4',
          value: 86.7,
          text: '6,5h'
        },
        {
          key: '5',
          value: 80,
          text: '6,0h'
        },
        {
          key: '6',
          value: 73.3,
          text: '5,5h'
        },
        {
          key: '7',
          value: 66.7,
          text: '5,0h'
        },
        {
          key: '8',
          value: 60,
          text: '4,5h'
        },
        {
          key: '9',
          value: 53.3,
          text: '4,0h'
        },
        {
          key: '10',
          value: 50,
          text: '3,75h'
        },
        {
          key: '11',
          value: 46.6,
          text: '3,5h'
        }
      ]
    },
    {
      text: 'Punëdhënësi',
      value: 'employeer',
      select: [
        { key: '1', value: 'Primar', text: 'Primar' },
        { key: '2', value: 'Sekondar', text: 'Sekondar' }
      ]
    },
    {
      text: 'Komente për rekrutim',
      value: 'comment_box',
      type: 'textarea'
    },
    {
      text: 'SPEEEX Badge',
      value: 'speeex_badge'
    },
    {
      text: 'Lokacioni i punës',
      value: 'office_location',
      select: [
        { key: '1', value: 'Home Office', text: 'Home Office' },
        { key: '2', value: 'Prishtinë', text: 'Prishtinë' },
        { key: '3', value: 'Prishtinë HQ', text: 'Prishtinë HQ' },
        { key: '4', value: 'Vushtrri', text: 'Vushtrri' },
        { key: '5', value: 'Ferizaj', text: 'Ferizaj' },
        { key: '6', value: 'Prizren', text: 'Prizren' }
      ]
    },
    {
      text: 'Statusi i punës',
      value: 'work',
      select: works
    },
    {
      text: 'Lejeqëndrim',
      value: 'residence_permit',
      checkbox: 'residence_permit'
    },
    {
      text: 'Llogaria e Trustit',
      value: 'trust_account',
      checkbox: 'trust_account'
    },
    {
      text: 'Llogaritet Trusti në pagë',
      value: 'should_include_trust',
      checkbox: 'should_include_trust'
    },
    {
      text: 'Certifikata nen hetime',
      value: 'court_certificate',
      checkbox: 'court_certificate'
    },
    {
      text: 'Certifikata shendetesore',
      value: 'health_certificate',
      checkbox: 'health_certificate'
    },
    {
      text: 'SSHP',
      value: 'health_safety_certificate',
      checkbox: 'health_safety_certificate'
    },
    {
      text: 'Data e lindjes',
      value: 'birthdate',
      datepicker: 'birthdate'
    },
    {
      text: 'Dita e parë',
      value: 'first_call',
      datepicker: 'first_call'
    },
    {
      text: 'Gjinia',
      value: 'gender',
      select: [
        { key: '1', value: 'Mashkull', text: 'Mashkull' },
        { key: '2', value: 'Femer', text: 'Femer' }
      ]
    },
    {
      text: 'Nacionaliteti',
      value: 'nationality'
    },
    {
      text: 'Shtetësia',
      value: 'citizenship'
    },
    {
      text: 'Niveli i Perdoruesit',
      value: 'user_level',
      select: [
        { key: '1', value: '1', text: 'Administrator' },
        { key: '2', value: '2', text: 'Manager' },
        { key: '3', value: '3', text: 'Team Leader' },
        { key: '4', value: '4', text: 'Perdorues i thjeshte' },
        { key: '5', value: '5', text: 'HR Manager' },
        { key: '6', value: '6', text: 'HR Admin' },
        { key: '7', value: '7', text: 'Mirembajtes' },
        { key: '8', value: '8', text: 'Koordinator i pushimeve' }
      ]
    },
    {
      text: 'Projekti',
      value: 'project',
      select: projects
    },
    {
      text: 'Departamenti',
      value: 'department',
      select: departments
    },
    {
      text: 'Manager',
      value: 'manager',
      select: managers
    },
    {
      text: 'Project Manager',
      value: 'has_project_manager',
      select: projectManagers
    },
    {
      text: 'Team Leader',
      value: 'teamleader',
      select: teamLeaders
    },
    {
      text: 'Funksioni',
      value: 'function',
      select: functions
    },
    {
      text: 'Gjuhet',
      value: 'languages',
      select: languages
    }
  ]
}

export const requiredApplicantFields = [
  {
    text: 'Email Adresa',
    value: 'email'
  },
  {
    text: 'Fjalekalimi',
    value: 'password'
  },
  {
    text: 'Numri i leternjoftimit',
    value: 'idNumber'
  },
  {
    text: 'Emri',
    value: 'firstName'
  },
  {
    text: 'Mbiemri',
    value: 'lastName'
  },
  {
    text: 'Niveli i Perdoruesit',
    value: 'user_level'
  },
  {
    text: 'Manager',
    value: 'manager'
  },
  {
    text: 'Statusi martesor',
    value: 'marital_status'
  },
  {
    text: 'Funksioni',
    value: 'function'
  },
  {
    text: 'Aktiviteti',
    value: 'activity'
  },
  {
    text: 'Koha e punes se punetorit',
    value: 'work_percentage'
  },
  {
    text: 'Punëdhënësi',
    value: 'employeer'
  },
  {
    text: 'Lokacioni i punës',
    value: 'office_location'
  },
  {
    text: 'Data e lindjes',
    value: 'birthdate'
  },
  {
    text: 'Dita e parë',
    value: 'first_call'
  },
  {
    text: 'Departamenti',
    value: 'department'
  },
  {
    text: 'Certifikata nen hetime',
    value: 'court_certificate'
  },
  {
    text: 'Certifikata shendetesore',
    value: 'health_certificate'
  },
  {
    text: 'Llogaria Bankare',
    value: 'bank_account_no'
  },
  {
    text: 'Banka',
    value: 'bank'
  },
  {
    text: 'Statusi i punës',
    value: 'work'
  },
  {
    text: 'Projekti',
    value: 'project'
  },
  {
    text: 'Gjuhet',
    value: 'languages'
  },
  { text: 'Numri Kontaktit', value: 'contactNumber' },
  { text: 'Adresa', value: 'birthplace' }
]

export const buttonsApplicants = [
  {
    type: 'low',
    text: 'Cilëso si të dobët',
    showOnStatus: ['new', 'testing']
  },
  {
    type: 'spxedu',
    text: 'Dërgo në Speeex Education',
    showOnStatus: ['new', 'testing']
  },
  {
    type: 'testing',
    text: 'Dërgo në testim',
    showOnStatus: ['new']
  },
  {
    type: 'waiting_list',
    text: 'Dërgo në listën e pritjes',
    showOnStatus: ['new', 'testing']
  },
  {
    type: 'recruited',
    text: 'Dërgo në rekrutim',
    showOnStatus: ['waiting_list']
  },
  {
    type: 'declined',
    text: 'Shëno si refuzues',
    showOnStatus: ['new', 'low', 'spxedu', 'testing', 'waiting_list', 'recruited']
  },
  {
    type: 'blacklist',
    text: 'Dërgo në listën e zezë',
    showOnStatus: ['new', 'low', 'spxedu', 'testing', 'waiting_list', 'recruited', 'declined']
  }
]
